// @ts-nocheck
"use client"

import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@/components/ui/accordion";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function UseCasesSection() {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <Card className="w-full max-w-2xl text-left">
        <CardHeader className="text-center text-left">
          <CardTitle className="text-2xl font-bold">Example Use Cases</CardTitle>
        </CardHeader>
        <CardContent>
          <Accordion type="single" collapsible>
            <AccordionItem value="new-restaurants text-left">
              <AccordionTrigger>
                Daily New Restaurant Businesses in London
              </AccordionTrigger>
              <AccordionContent>
                Get a daily list of newly opened restaurants in London. Information can be delivered directly on our platform, emailed as a list, or accessed via API.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="singaporean-owners text-left">
              <AccordionTrigger>
                Singaporeans Owning UK Companies
              </AccordionTrigger>
              <AccordionContent>
                Discover which Singaporean entrepreneurs are owning UK companies. Choose from direct access on our dashboard, receive periodic email updates, or integrate via API.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="rebranded-companies text-left">
              <AccordionTrigger>
                Companies That Rebranded Last Month
              </AccordionTrigger>
              <AccordionContent>
                Explore companies that have rebranded in the last month. Data is available for direct viewing, email delivery, or API integration.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="established-companies text-left">
              <AccordionTrigger>
                Established Companies with Experienced Leadership
              </AccordionTrigger>
              <AccordionContent>
                View companies over 10 years old, with directors nearing retirement and a turnover of over £100,000. Get this tailored data directly online, via email, or through our API.
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </CardContent>
      </Card>
    </div>
  );
}
