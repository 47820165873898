// @ts-nocheck
'use client'


import { ExampleLineChart } from "@/components/ExampleLineChart";
import { ExampleRadialChart } from "@/components/ExampleRadialChart";
import { ExampleBarChart } from "@/components/ExampleBarChart";
import { ExampleDonutChart } from "./ExampleDonutChart";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";

import { Building2, Database, ChartSpline, Users, TrendingUp } from "lucide-react";
import { Overview } from "@/components/Overview";

import styles from "./Home.module.css";

import { Button } from "@/components/ui/button";
import Link from "next/link";

import AnimatedNumber from "@/components/AnimatedNumber";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import TableColumnsDropdownMenu from "@/components/TableColumnsDropdownMenu";
import TableColumnsTooltipDropdown from "@/components/TableColumnsTooltipDropdown";

import { ChevronDown } from "lucide-react";

import { useState } from "react";
import { useRouter } from "next/navigation";

export default function DataPointsCard({ isActive }: DataPointsCardProps) {
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const handleClick = () => {
    setLoading(true);
    // Navigate to the dashboard
    router.push("/dashboard");
  };

  return (
    <>
      <div className="flex flex-col justify-center gap-0 relative -top-[3vh]">
        <div className="hidden flex-col md:flex p-10">
          <div className="flex-1 space-y-4 p-0 pt-10">
            <div className="flex items-center justify-between space-y-2">
              <h2 className="text-3xl font-bold tracking-tight">
                What’s in the Database
              </h2>
              {/* Removed the top prototype button */}
            </div>
          </div>
          <Tabs defaultValue="overview" className="space-y-4">
            <TabsContent value="overview" className="space-y-4">
              <div className="grid gap-5 md:grid-cols-5 lg:grid-cols-5">
                {/* Companies Card */}
                <TableColumnsTooltipDropdown category="Companies">
                  <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium text-left">
                        Companies
                      </CardTitle>
                      <Building2 className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold text-left">
                        <AnimatedNumber
                          value={5.3}
                          decimals={1}
                          isActive={isActive}
                          delay={1000}
                          duration={2500}
                        />
                        + Million
                      </div>
                      <p className="text-xs text-muted-foreground text-left">
                        Active UK Registered Companies.
                      </p>
                      <ChevronDown />
                    </CardContent>
                  </Card>
                </TableColumnsTooltipDropdown>

                {/* Accounts Card */}
                <TableColumnsTooltipDropdown category="Accounts">
                  <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 text-left">
                      <CardTitle className="text-sm font-medium text-left">
                        Accounts
                      </CardTitle>
                      <ChartSpline className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold text-left">
                        <AnimatedNumber
                          value={9.5}
                          decimals={1}
                          isActive={isActive}
                          delay={1000}
                          duration={2500}
                        />
                        + Million
                      </div>
                      <p className="text-xs text-muted-foreground text-left">
                        Digitally Filed Accounts Recorded
                      </p>
                      <ChevronDown />
                    </CardContent>
                  </Card>
                </TableColumnsTooltipDropdown>

                {/* Data Points Card */}
                <TableColumnsTooltipDropdown category="All">
                  <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium text-left">
                        Data Points
                      </CardTitle>
                      <Database className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold text-[#ff29e6] text-left">
                        <AnimatedNumber
                          value={8.1}
                          decimals={1}
                          isActive={isActive}
                          delay={1000}
                          duration={2500}
                        />
                        + Billion
                      </div>
                      <p className="text-xs text-muted-foreground text-left">
                        Datapoints held on UK companies.
                      </p>
                      <ChevronDown />
                    </CardContent>
                  </Card>
                </TableColumnsTooltipDropdown>

                {/* Shareholders Card */}
                <TableColumnsTooltipDropdown category="Shareholders">
                  <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium">
                        Shareholders
                      </CardTitle>
                      <Users className="h-4 w-4 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold text-left">
                        <AnimatedNumber
                          value={12.6}
                          decimals={1}
                          isActive={isActive}
                          delay={1000}
                          duration={2500}
                        />
                        + Million
                      </div>
                      <p className="text-xs text-muted-foreground text-left">
                        Shareholder Records.
                      </p>
                      <ChevronDown />
                    </CardContent>
                  </Card>
                </TableColumnsTooltipDropdown>

                {/* Officers Card */}
                <TableColumnsTooltipDropdown category="Officers">
                  <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                      <CardTitle className="text-sm font-medium text-left">
                        Officers
                      </CardTitle>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="h-4 w-4 text-muted-foreground"
                      >
                        <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                      </svg>
                    </CardHeader>
                    <CardContent>
                      <div className="text-2xl font-bold text-left">
                        <AnimatedNumber
                          value={18.7}
                          decimals={1}
                          isActive={isActive}
                          delay={1000}
                          duration={2500}
                        />
                        + Million
                      </div>
                      <p className="text-xs text-muted-foreground text-left">
                        (Directors, Secretary) Records.
                      </p>
                      <ChevronDown />
                    </CardContent>
                  </Card>
                </TableColumnsTooltipDropdown>
              </div>
              <div className="grid gap-4 md:grid-cols-7 lg:grid-cols-7 text-left">
                <Card className="col-span-7">
                  <CardHeader>
                    <CardTitle>Near Real-Time Updates (NRT)</CardTitle>
                  </CardHeader>
                  <CardContent className="pl-2">
                    <Overview />
                  </CardContent>
                  <CardFooter>
                    <div className="flex w-full items-start gap-2 text-sm">
                      <div className="grid gap-2">
                        <div className="flex items-center gap-2 font-medium leading-none">
                          Records are updated on average, between 40 to 80 seconds of being published by Companies House.
                          <TrendingUp className="h-4 w-4" />
                        </div>
                        <div className="flex items-center gap-2 leading-none text-muted-foreground">
                          This graph shows an example of the average time it takes for records to be updated.
                        </div>
                      </div>
                    </div>
                  </CardFooter>
                </Card>
              </div>
            </TabsContent>
          </Tabs>
          {/* Global Prototype Button at the bottom */}
          <div className="flex flex-col items-center mt-8">
            <p className="mb-4 text-xl text-muted-foreground text-center">
            Experience our AI-powered chat: request UK Company data using everyday language - no code needed. Click below to try our prototype!
            </p>
            <Button
              size="lg"
              onClick={handleClick}
              disabled={loading}
              className="bg-[#ff29e6] text-white hover:bg-[#ff29e6]/80"
            >
              {loading ? "Loading..." : "Try Our Prototype AI Bot For Free"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
