// @ts-nocheck
'use client'

import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

interface KaraokeScrollProps {
  paragraph: string;
  fontSize?: string;
  textColor?: string;
  highlightColor?: string;
}

export default function KaraokeScroll({
  paragraph,
  fontSize = "2xl",
  textColor = "#999",
  highlightColor = "#ff29e6",
}: KaraokeScrollProps) {
  const [progress, setProgress] = useState(0);
  const containerRef = useRef(null);
  const lastProgress = useRef(0);

  const updateProgress = (newProgress: number) => {
    newProgress = Math.max(0, Math.min(1, newProgress));
    requestAnimationFrame(() => {
      // Increase smoothing factor for a faster update
      setProgress((prev) => prev + (newProgress - prev) * 0.2);
    });
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { top, height } = containerRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const scrollProgress = 1 - top / (height - windowHeight);
      // Increase weight on scrollProgress so highlighting occurs sooner
      updateProgress(scrollProgress * 1.0 + lastProgress.current * 0.0);
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (containerRef.current) {
      const { left, top, width, height } = containerRef.current.getBoundingClientRect();
      const mouseY = (e.clientY - top) / height;
      const mouseX = (e.clientX - left) / width;
      const moveProgress = (mouseY + mouseX) / 2;
      // Increase weight on moveProgress so highlighting occurs sooner
      updateProgress(moveProgress * 1.0 + lastProgress.current * 0.0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div ref={containerRef} className="relative w-full flex items-center justify-center px-4">
      <motion.div
        className={`max-w-2xl text-center leading-relaxed text-${fontSize}`}
        style={{ wordWrap: "break-word", overflowWrap: "break-word", textAlign: "center", color: textColor }}
      >
        {paragraph.split(" ").map((word, index) => {
          const wordProgress = index / paragraph.split(" ").length;
          return (
            <motion.span
              key={index}
              className="inline-block mr-1"
              style={{ position: "relative", whiteSpace: "nowrap" }}
            >
              <span
                style={{
                  color: wordProgress < progress ? highlightColor : "transparent",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  visibility: wordProgress < progress ? "visible" : "hidden",
                }}
              >
                {word}
              </span>
              <span style={{ color: textColor, visibility: wordProgress < progress ? "hidden" : "visible" }}>
                {word}
              </span>
            </motion.span>
          );
        })}
      </motion.div>
    </div>
  );
}
