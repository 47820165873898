// @ts-nocheck
'use client'

import React, { useState } from "react";
import { DM_Serif_Text, Poppins, Montserrat } from "next/font/google";
import KaraokeScroll from "@/components/KaraokeScroll"; // Import KaraokeScroll component

// Available fonts
const dmSerifFont = DM_Serif_Text({ subsets: ["latin"], weight: ["400"] }); // DM Serif Text has only one weight
const poppinsFont = Poppins({ subsets: ["latin"], weight: ["400", "700"] });
const montserratFont = Montserrat({ subsets: ["latin"], weight: ["400", "700"] });

export default function DescriptionSection({ isActive }) {
  // State to control font selection
  const [selectedFont, setSelectedFont] = useState(poppinsFont); // Default to Poppins
  const [fontSize, setFontSize] = useState("3xl"); // Default font size
  const [textColor, setTextColor] = useState("#333"); // Default text color
  const [highlightColor, setHighlightColor] = useState("#ff29e6"); // Default highlight color

  const descriptionParagraph =
  "We provide a comprehensive UK Company Database, and deliver the insights you need in the way that suits you best.";
    /* "We empower you with a comprehensive UK company database full of valuable insights, along with tailored AI-driven automation solutions that enhance efficiency, lower costs, and drive growth."; */

  return (
    <div className={`relative w-full min-h-[calc(100vh-5vh)] flex flex-col items-center justify-center overflow-visible ${selectedFont.className}`}>
      {/* Controls for changing font, size, and colors */}
     {/*  <div className="mb-4 flex gap-4">
        <select onChange={(e) => setSelectedFont(e.target.value === "dm" ? dmSerifFont : e.target.value === "montserrat" ? montserratFont : poppinsFont)}
          className="p-2 border rounded">
          <option value="poppins">Poppins</option>
          <option value="montserrat">Montserrat</option>
          <option value="dm">DM Serif Text</option>
        </select>

        <select onChange={(e) => setFontSize(e.target.value)} className="p-2 border rounded">
          <option value="text-xl">XL</option>
          <option value="text-2xl">2XL</option>
          <option value="text-3xl">3XL</option>
          <option value="text-4xl">4XL</option>
        </select>

        <input type="color" value={textColor} onChange={(e) => setTextColor(e.target.value)} className="w-10 h-10" />

        <input type="color" value={highlightColor} onChange={(e) => setHighlightColor(e.target.value)} className="w-10 h-10" />
      </div> */}

      {/* KaraokeScroll Component with dynamic props */}

      
      <div className="flex flex-col items-center justify-center gap-0 relative -top-[3vh]">

      <KaraokeScroll paragraph={descriptionParagraph} fontSize={fontSize} textColor={textColor} highlightColor={highlightColor} scrollSensitivity={2.5}  />
      </div>
    </div>
  );
}
