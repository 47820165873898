// @ts-nocheck
'use client'

import * as React from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { tableColumnsMap } from "@/data/tableColumns"; // Import table mapping

interface TableColumnsTooltipProps {
  category: string;
  children: React.ReactNode; // Allows wrapping a card
}

export default function TableColumnsTooltip({ category, children }: TableColumnsTooltipProps) {
  const columns = tableColumnsMap[category] || [];

  return (
    <TooltipProvider delayDuration={100} skipDelayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent side="bottom" align="center" className="w-72 max-h-40 overflow-y-auto p-2 text-sm text-left border shadow-lg bg-white rounded-md">
          <p className="font-semibold mb-2">{category} - Fields/Columns Included</p>
          <ul className="list-disc pl-4">
            {columns.map((col, index) => (
              <li key={index} className="truncate">{col.replace(/_/g, " ")}</li>
            ))}
          </ul>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
