export const tableColumnsMap: Record<string, string[]> = {
    Companies: [
      "Accounts_Category",
      "Accounts_Last_Made_Up_Date",
      "Accounts_Next_Due_Date",
      "Accounts_Ref_Day",
      "Accounts_Ref_Month",
      "Address_Care_Of",
      "Address_Line1",
      "Address_Line2",
      "Category",
      "Company_Name",
      "Company_Number",
      "Conf_Stmt_Last_Made_Up_Date",
      "Conf_Stmt_Next_Due_Date",
      "Country",
      "Country_Of_Origin",
      "County",
      "Dissolution_Date",
      "Incorporation_Date",
      "Limited_Partnerships_Num_Gen_Partners",
      "Limited_Partnerships_Num_Lim_Partners",
      "Mortgages_Num_Mort_Charges",
      "Mortgages_Num_Mort_Outstanding",
      "Mortgages_Num_Mort_Part_Satisfied",
      "Mortgages_Num_Mort_Satisfied",
      "PO_Box",
      "Post_Code",
      "Post_Town",
      "Primary_Business_Sector",
      "Returns_Last_Made_Up_Date",
      "Returns_Next_Due_Date",
      "Siccode_Sic_Text_1",
      "Siccode_Sic_Text_2",
      "Siccode_Sic_Text_3",
      "Siccode_Sic_Text_4",
      "Status",
      "Uri",
    ],
    Accounts: [
      "Account_Detail",
      "Actual_Value",
      "Company_Name",
      "Company_Number",
      "Currency",
      "Fiscal_Year",
      "Report_Date",
    ],
    All: [
      "Company_Name",
      "Company_Number",
      "Primary_Business_Sector",
      "Previous_Name_1_Company_Name",
      "Previous_Name_1_Condate",
      "Previous_Name_2_Company_Name",
      "Previous_Name_2_Condate",
      "Previous_Name_3_Company_Name",
      "Previous_Name_3_Condate",
      "Previous_Name_4_Company_Name",
      "Previous_Name_4_Condate",
      "Returns_Last_Made_Up_Date",
      "Returns_Next_Due_Date",
      "Uri",
    ],
    Shareholders: [
      "Company_Name",
      "Company_Number",
      "Full_Name",
      "Forename",
      "Surname",
      "Nationality",
      "Age",
      "Legal_Authority",
      "Legal_Form",
      "Place_Registered",
      "Registration_Number",
      "Self_Link",
      "Statement_Link",
      "Withdrawal_Reason",
    ],
    Officers: [
      "Company_Name",
      "Company_Number",
      "Full_Name",
      "Forenames",
      "Surname",
      "Title",
      "Appointment_Date",
      "Resignation_Date",
      "Occupation",
      "Nationality",
      "Honours",
      "Age",
      "Partial_Date_Of_Birth",
      "Address_Line_1",
      "Address_Line_2",
      "County",
      "Post_Town",
      "Person_Postcode",
      "Usual_Residential_Country",
    ],
  };
  