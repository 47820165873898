// @ts-nocheck
'use client'


import Link from "next/link";
import { Instagram } from "lucide-react";

export default function HomepageFooter() {
  return (
 /*    <footer className="fixed bottom-0 left-0 right-0 bg-background/50 border-t p-4 backdrop-blur-sm"> */
        <footer className=" bg-background/50 border-t p-4 backdrop-blur-sm">
      <div className="flex flex-col items-center">
        <div className="flex items-center space-x-2">
          <a
            href="https://www.instagram.com/datadini.ai/#"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-primary"
          >
            <Instagram className="h-4 w-4 text-muted-foreground" />
          </a>
          <span className="text-xs text-muted-foreground">
              Thank you for visiting Datadini.ai
      {/*       Datadini Ltd &middot; Company No. 08258574 &middot; Since 2012 */}
          </span>
        </div>
      </div>
    </footer>
  );
}
