// @ts-nocheck
'use client'

import React from "react";

import { motion } from "framer-motion";

interface DatadiniLogoProps extends React.ComponentProps<"svg"> {
  colors?: {
    D1?: string;
    A1?: string;
    T?: string;
    A2?: string;
    D2?: string;
    I1?: string;
    N?: string;
    I2?: string;
  };
  animations?: {
    D1?: object;
    A1?: object;
    T?: object;
    A2?: object;
    D2?: object;
    I1?: object;
    N?: object;
    I2?: object;
  };
}

export const DatadiniHighlightLogo: React.FC<DatadiniLogoProps> = ({
    className,
    colors = {},
    animations = {},
    ...props
  }) => {
  return (
     <svg
      viewBox="0 0 310 60"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g transform="translate(1) scale(1)">
        {/* Letter D */}
        <motion.path
          fill={colors.D1 || "red"}
          animate={animations.D1}
          d="m33.85,0v21.49c-.26-.94-1.03-1.96-2.3-3.08-1.26-1.11-2.91-2.08-4.95-2.9-2.03-.81-4.36-1.22-7-1.22-3.96,0-7.41.98-10.35,2.95-2.93,1.96-5.21,4.59-6.82,7.87-1.62,3.28-2.43,6.93-2.43,10.93s.81,7.64,2.43,10.92c1.61,3.28,3.89,5.9,6.82,7.85,1.05.69,2.17,1.27,3.35,1.72h13.32c1.91-.77,3.57-1.76,4.98-2.97,1.44-1.22,2.47-2.44,3.1-3.67v6.65h15.05V0h-15.2
            m-1.25,41.06c-.83,1.49-1.97,2.64-3.42,3.48-1.45.83-3.09,1.25-4.93,1.25s-3.36-.42-4.77-1.25c-1.42-.84-2.54-1.99-3.35-3.48-.82-1.48-1.23-3.16-1.23-5.02s.41-3.55,1.23-5.03c.81-1.48,1.93-2.65,3.35-3.5,1.41-.85,3.01-1.27,4.77-1.27s3.48.42,4.93,1.27c1.45.85,2.59,2.02,3.42,3.5.84,1.48,1.25,3.16,1.25,5.03s-.41,3.54-1.25,5.02Z"
        />
        {/* First Letter A */}
        <motion.path
          fill={colors.A1 || "blue"}
          animate={animations.A1}
          d="m81.35,15.49v6c-.47-.94-1.3-1.96-2.5-3.08-1.2-1.11-2.77-2.08-4.7-2.9-1.93-.81-4.18-1.22-6.75-1.22-4,0-7.5.98-10.5,2.95-3,1.96-5.33,4.59-7,7.87-.32.62-.6,1.25-.85,1.9-1.1,2.77-1.65,5.79-1.65,9.03s.55,6.25,1.65,9.02c.25.65.53,1.28.85,1.9,1.67,3.28,4,5.9,7,7.85,1.07.69,2.21,1.27,3.41,1.72h13.41c.23-.1.46-.21.68-.32,2-1.01,3.61-2.16,4.83-3.42,1.21-1.27,1.97-2.24,2.27-2.9v6.65h14.95V15.49h-15.1
            m-1.27,25.57c-.82,1.49-1.93,2.64-3.33,3.48-1.4.83-3.02,1.25-4.85,1.25s-3.37-.42-4.82-1.25c-1.45-.84-2.61-1.99-3.48-3.48-.87-1.48-1.3-3.16-1.3-5.02s.43-3.55,1.3-5.03c.87-1.48,2.03-2.65,3.48-3.5,1.45-.85,3.05-1.27,4.82-1.27s3.45.42,4.85,1.27c1.4.85,2.51,2.02,3.33,3.5.81,1.48,1.22,3.16,1.22,5.03s-.41,3.54-1.22,5.02Z"
        />
        {/* Letter T */}
        <motion.path
          fill={colors.T || "green"}
          animate={animations.T}
          d="m131.92,53.54c-.23.2-2.28,1.56-3.49,2.1-.72.31-1.55.61-2.5.89h-14.88c-1.34-.56-2.56-1.32-3.65-2.27-2.67-2.31-4-5.89-4-10.72v-15.55h-7v-12.5h7V0h15.05v15.49h19.84s-10.64,12.5-10.64,12.5h-9.2v11.45c0,1.7.25,3.07.75,4.12.5,1.05,1.42,1.58,2.75,1.58.9,0,1.67-.2,2.3-.6.34-.22.62-.4.81-.54.17-.11.28-.20.34-.26l3.37,7.01h.01s3.14,2.79,3.14,2.79Z"
        />
        {/* Second Letter A */}
        <motion.path
          fill={colors.A2 || "orange"}
          animate={animations.A2}
          d="m157.75,15.49v6c-.47-.94-1.3-1.96-2.5-3.08-1.2-1.11-2.77-2.08-4.7-2.9-1.93-.81-4.18-1.22-6.75-1.22-4,0-7.5.98-10.5,2.95-2.27,1.48-4.15,3.34-5.65,5.58-.49.72-.94,1.49-1.35,2.29-.47.93-.88,1.89-1.21,2.88-.86,2.5-1.29,5.18-1.29,8.05s.42,5.49,1.26,7.96c.34,1.02.75,2.01,1.24,2.96.7,1.38,1.52,2.64,2.47,3.79h.01c1.28,1.59,2.78,2.93,4.52,4.06,1.07.69,2.21,1.27,3.41,1.72h13.41c.23-.1.46-.21.68-.32,2-1.01,3.61-2.16,4.83-3.42,1.21-1.27,1.97-2.24,2.27-2.9v6.65h14.95V15.49h-15.1
            m-1.27,25.57c-.82,1.49-1.93,2.64-3.33,3.48-1.4.83-3.02,1.25-4.85,1.25s-3.37-.42-4.82-1.25c-1.45-.84-2.61-1.99-3.48-3.48-.87-1.48-1.3-3.16-1.3-5.02s.43-3.55,1.3-5.03c.87-1.48,2.03-2.65,3.48-3.5,1.45-.85,3.05-1.27,4.82-1.27s3.45.42,4.85,1.27c1.4.85,2.51,2.02,3.33,3.5.81,1.48,1.22,3.16,1.22,5.03s-.41,3.54-1.22,5.02Z"
        />
        {/* Second Letter D */}
        <motion.path
          fill={colors.D2 || "purple"}
          animate={animations.D2}
          d="m206.15,0v21.49c-.27-.94-1.03-1.96-2.3-3.08-1.27-1.11-2.92-2.08-4.95-2.9-2.03-.81-4.37-1.22-7-1.22-3.97,0-7.42.98-10.35,2.95-2.93,1.96-5.21,4.59-6.82,7.87-.86,1.73-1.48,3.57-1.88,5.5-.37,1.73-.55,3.54-.55,5.43s.18,3.7.55,5.42c.39,1.94,1.02,3.77,1.88,5.5,1.61,3.28,3.89,5.9,6.82,7.85,1.04.69,2.16,1.27,3.34,1.72h13.33c1.9-.77,3.57-1.77,4.98-2.97,1.43-1.22,2.47-2.44,3.1-3.67v6.65h15.05V0h-15.2
            m-1.25,41.06c-.83,1.49-1.97,2.64-3.42,3.48-1.45.83-3.1,1.25-4.93,1.25s-3.36-.42-4.77-1.25c-1.42-.84-2.54-1.99-3.35-3.48-.82-1.48-1.23-3.16-1.23-5.02s.41-3.55,1.23-5.03c.81-1.48,1.93-2.65,3.35-3.5,1.41-.85,3.01-1.27,4.77-1.27s3.48.42,4.93,1.27c1.45.85,2.59,2.02,3.42,3.5.83,1.48,1.25,3.16,1.25,5.03s-.41,3.54-1.25,5.02Z"
        />
        {/* Letter I */}
        <motion.path
          fill={colors.I1 || "cyan"}
          animate={animations.I1}
          d="m223.17,15.49v41.05h15.2V15.49h-15.2 m-.67-15.49c.28,1.7,1.07,3.2,2.37,4.51,1.66,1.69,3.7,2.53,6.1,2.53s4.4-.84,6.1-2.53c1.32-1.31,2.13-2.81,2.42-4.51h-16.99Z"
        />
        {/* Letter N */}
        <motion.path
          fill={colors.N || "pink"}
          animate={animations.N}
          d="m284.68,21.63c-1.76-2.51-4.01-4.36-6.75-5.52-2.73-1.17-5.53-1.75-8.4-1.75-2.26,0-4.37.37-6.32,1.12-1.95.75-3.57,1.68-4.85,2.8-1.29,1.12-2.13,2.23-2.53,3.33v-6.05h-15.2v41.05h15.2v-22.05c0-1.84.41-3.46,1.23-4.88.81-1.41,1.88-2.52,3.2-3.3,1.31-.78,2.72-1.17,4.22-1.17,2.27,0,4.1.74,5.5,2.22,1.4,1.48,2.1,3.69,2.1,6.63v22.55h15.25v-25.05c0-4.1-.88-7.41-2.65-9.93Z"
        />
        {/* Last Letter I */}
        <motion.path
          fill={colors.I2 || "yellow"}
          animate={animations.I2}
          d="m288.48,0c.28,1.7,1.07,3.2,2.37,4.51,1.66,1.69,3.7,2.53,6.1,2.53s4.4-.84,6.1-2.53c1.32-1.31,2.13-2.81,2.42-4.51h-16.99 m.67,15.49v41.05h15.2V15.49h-15.2Z"
        />
      </g>
    </svg>
  );
};

export default DatadiniHighlightLogo;
