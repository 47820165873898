// @ts-nocheck
"use client";

import React from "react";
import { useInView } from "react-intersection-observer"; // Detects when element is in view
import { Line, LineChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis } from "recharts";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";

const chartData = [
  { time: "Monday", profiles: 42, officers: 66, shareholders: 40 },
  { time: "Tuesday", profiles: 77, officers: 45, shareholders: 60 },
  { time: "Wednesday", profiles: 43, officers: 75, shareholders: 50 },
  { time: "Thursday", profiles: 77, officers: 41, shareholders: 68 },
  { time: "Friday", profiles: 50, officers: 70, shareholders: 42 },
];

const chartConfig = {
  profiles: { label: "Profiles", color: "hsl(var(--chart-1))" },
  officers: { label: "Officers", color: "hsl(var(--chart-2))" },
  shareholders: { label: "Shareholders", color: "hsl(var(--chart-3))" },
};

export function Overview() {
  const { ref, inView } = useInView({
    triggerOnce: false, // Re-triggers when scrolled back
    threshold: 0.3, // Trigger when 30% of the chart is visible
  });

  return (
    <div ref={ref} className="w-full">
      {inView && ( // Only render when in view
        <ResponsiveContainer width="100%" height={300}>
          <ChartContainer config={chartConfig}>
            <LineChart
              data={chartData}
              margin={{ top: 20, right: 20, bottom: 0, left: 12 }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="time"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={(value) => value.slice(0, 3)}
              />
              <YAxis domain={["dataMin - 5", "dataMax + 5"]} tick={false} axisLine={false} tickLine={false} />
              <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
              <Line dataKey="profiles" type="monotone" stroke="#fae8ff" strokeWidth={2} dot={false} />
              <Line dataKey="officers" type="monotone" stroke="#d946ef" strokeWidth={2} dot={false} />
              <Line dataKey="shareholders" type="monotone" stroke="fuchsia" strokeWidth={2} dot={false} />
            </LineChart>
          </ChartContainer>
        </ResponsiveContainer>
      )}
    </div>
  );
}
