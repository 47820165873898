// @ts-nocheck
'use client'


import React from "react";
import { motion } from "framer-motion";
import { DatadiniIcon } from "@/components/Icons";
import { DatadiniHighlightLogo } from "@/components/DatadiniHighlightLogo";

// Accept an isActive prop to control the icon exit animation.
export default function HeroSection({ isActive }) {
  return (
    <div className="relative w-full min-h-[calc(100vh-5vh)] flex flex-col items-center justify-center overflow-visible">
      <div className="flex flex-col items-center justify-center gap-0 relative -top-[3vh]">
        {/* ICON: Fade in and, when not active, drop off the page */}
        <motion.div
          initial={{ opacity: 0, y: "-5vh" }}
          animate={{
            opacity: 1,
            y: isActive ? "-5vh" : "700vh", // when not active, animate the icon falling down off-screen
          }}
          transition={{ duration: 2, ease: "easeInOut" }}
          className="w-24 h-24 -mb-10"
        >
          <DatadiniIcon />
        </motion.div>

        {/* CODE TEXT + HIGHLIGHT LOGO INLINE */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 3, ease: "easeInOut" }}
          className="text-center font-din text-2xl flex items-center space-x-2"
        >
          {/* Left Side: { data + ai } = */}
          <code className="text-black">
            <span className="text-black">{"{ "}</span>
            <span className="text-black"> data </span>
            <span className="text-black"> + </span>
            <span className="text-black"> ai </span>
            <span className="text-black">{" }"}</span>
            <span className="text-black"> = </span>
          </code>

          {/* Right Side: Highlight Logo with custom animation */}
          <DatadiniHighlightLogo
            className="h-12 w-auto align-middle"
            colors={{
              D1: "#ff29e6",
              A1: "#ff29e6",
              T: "#ff29e6",
              A2: "#ff29e6",
              D2: "#ff29e6",
              I1: "#ff29e6",
              N: "#ff29e6",
              I2: "#ff29e6",
            }}
            animations={{
              A1: { opacity: [0, 1, 1, 0], transition: { duration: 12, times: [0, 0.1667, 0.8333, 1], ease: "easeInOut", repeat: Infinity } },
              I1: { opacity: [0, 1, 1, 0], transition: { duration: 12, times: [0, 0.1667, 0.8333, 1], ease: "easeInOut", repeat: Infinity } },
              A2: { opacity: [0, 1, 1, 0], transition: { duration: 12, times: [0, 0.1667, 0.8333, 1], ease: "easeInOut", repeat: Infinity } },
              I2: { opacity: [0, 1, 1, 0], transition: { duration: 12, times: [0, 0.1667, 0.8333, 1], ease: "easeInOut", repeat: Infinity } },
              D1: { opacity: [0, 0, 1, 1, 0, 0], transition: { duration: 12, times: [0, 0.2222, 0.5, 0.6667, 0.8333, 1], ease: "easeInOut", repeat: Infinity } },
              T: { opacity: [0, 0, 1, 1, 0, 0], transition: { duration: 12, times: [0, 0.2222, 0.5, 0.6667, 0.8333, 1], ease: "easeInOut", repeat: Infinity } },
              D2: { opacity: [0, 0, 1, 1, 0, 0], transition: { duration: 12, times: [0, 0.2222, 0.5, 0.6667, 0.8333, 1], ease: "easeInOut", repeat: Infinity } },
              N: { opacity: [0, 0, 1, 1, 0, 0], transition: { duration: 12, times: [0, 0.2222, 0.5, 0.6667, 0.8333, 1], ease: "easeInOut", repeat: Infinity } },
            }}
          />
        </motion.div>
       {/*  <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 3, ease: "easeInOut" }}
          className="text-center text-2xl flex items-center space-x-2"
        ><p className="mt-3">
        "Thoughtfully Curated Data, Simplifying Complexity."
        </p></motion.div> */}
        
      </div>
    </div>
  );
}
