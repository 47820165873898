// @ts-nocheck
'use client'

import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import anime from "animejs";
import styles from "@/components/DatadiniCursorTrail.module.css"; 

export default function DatadiniCursorTrail() {
  const trailRef = useRef<HTMLDivElement>(null);
  const lastSpawnTime = useRef(0);

  // Set min & max spawn delay (adjust these for more/less density)
  const minSpawnDelay = 50;  // Minimum time between particles (ms)
  const maxSpawnDelay = 100; // Maximum time between particles (ms)

  useEffect(() => {
    const trailContainer = trailRef.current;
    if (!trailContainer) return;
    const colors = ["#FF6347", "#FFD700", "#7CFC00", "#00FA9A", "#00CED1", "#1E90FF", "#FF69B4"];

    const createParticle = (x: number, y: number) => {
      const now = Date.now();
      const spawnDelay = Math.random() * (maxSpawnDelay - minSpawnDelay) + minSpawnDelay;
      if (now - lastSpawnTime.current < spawnDelay) return;
      lastSpawnTime.current = now;

      const particle = document.createElement("div");
      particle.classList.add(styles.particle);
      particle.style.left = `${x}px`;
      particle.style.top = `${y}px`;
      particle.style.color = colors[Math.floor(Math.random() * colors.length)];
      particle.style.transform = `rotate(${Math.random() * 360}deg)`;

      particle.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" fill="currentColor" width="32" height="32">
          <g transform="translate(0,1080) scale(0.1,-0.1)">
            <path d="M5155 7719 c-533 -25 -769 -44 -1065 -85 -1022 -142 -1576 -468 -1753 -1029 -31 -98 -57 -270 -57 -373 0 -312 125 -763 317 -1146 112 -224 299 -513 469 -726 130 -163 457 -493 609 -614 333 -266 729 -478 1085 -580 627 -181 1278 -100 1884 234 917 507 1685 1565 1857 2557 103 597 -71 1056 -506 1338 -314 205 -751 329 -1370 389 -421 42 -1025 56 -1470 35z m1200 -434 c520 -50 944 -175 1110 -327 87 -79 106 -144 63 -219 -83 -148 -447 -268 -941 -308 -163 -14 -573 -14 -748 -1 -739 57 -1293 262 -1293 480 0 90 73 162 236 232 196 84 469 135 868 162 115 8 552 -4 705 -19z"/>
          </g>
        </svg>
      `;

      trailContainer.appendChild(particle);

      anime({
        targets: particle,
        translateY: [
          { value: -10, duration: 500 },
          { value: 5, duration: 500 },
        ],
        translateX: [
          { value: (Math.random() - 0.5) * 20, duration: 500 },
        ],
        rotate: {
          value: Math.random() > 0.5 ? "+=360deg" : "-=360deg",
          duration: 2000 + Math.random() * 1000,
          easing: "easeInOutSine",
        },
        opacity: [
          { value: 1, duration: 200 },
          { value: 0, duration: 1000 },
        ],
        scale: [
          { value: 1.2, duration: 200, easing: "easeOutBack" },
          { value: 0.8, duration: 800, easing: "easeInOutSine" },
        ],
        easing: "easeInOutSine",
        duration: 1000,
        complete: () => {
          particle.remove();
        },
      });
    };

    const onMouseMove = (e: MouseEvent) => {
      createParticle(e.clientX, e.clientY);
    };

    window.addEventListener("mousemove", onMouseMove);
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  // Only create the portal if document exists
  if (typeof document === "undefined") return null;

  return createPortal(
    <div ref={trailRef} className={styles.trailContainer}></div>,
    document.body
  );
}
