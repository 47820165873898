// @ts-nocheck
"use client";

import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import BottomBouncingDatadiniIcon from "@/components/BottomBouncingDatadiniIcon";
import HeroSection from "@/components/HeroSection";
import SimpleSection from "@/components/SimpleSection";
import DescriptionSection from "@/components/DescriptionSection";
import ChartSection from "@/components/ChartSection";
import ExampleUses from "@/components/ExampleUses";
import AutomationSection from "@/components/AutomationSection";
import WebsiteSection from "@/components/WebsiteSection";
import HomepageFooter from "@/components/HomepageFooter";
import DatadiniCursorTrail from "@/components/DatadiniCursorTrail";

export default function ScrollSnapPage() {
  const [activeSection, setActiveSection] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // Define your sections and corresponding components
  const sections = [
    "hero",
    "simple",
    "description",
    "chart",
    "exampleUses",
    "callToAction",
    "website",
  ];

  const sectionsWithCursorTrail = ["hero", "simple", "description", "callToAction"];

  const sectionComponents = [
    <HeroSection key="hero" isActive={activeSection === 0} />,
    <SimpleSection key="simple" isActive={activeSection === 1} />,
    <DescriptionSection key="description" isActive={activeSection === 2} />,
    <ChartSection key="chart" isActive={activeSection === 3} />,
    <ExampleUses key="exampleUses" isActive={activeSection === 4} />,
    <AutomationSection key="callToAction" isActive={activeSection === 5} />,
    <WebsiteSection key="website" isActive={activeSection === 6} />,
  ];

  // Scroll wheel logic
  const deltaYAccum = useRef(0);
  const threshold = 100;

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();
    deltaYAccum.current += e.deltaY;
    if (deltaYAccum.current > threshold) {
      setActiveSection((prev) => Math.min(sections.length - 1, prev + 1));
      deltaYAccum.current = 0;
    } else if (deltaYAccum.current < -threshold) {
      setActiveSection((prev) => Math.max(0, prev - 1));
      deltaYAccum.current = 0;
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("wheel", handleWheel, { passive: false });
    }
    return () => {
      if (container) container.removeEventListener("wheel", handleWheel);
    };
  }, []);

  // Background colors for each section
  const backgroundColors = [
    "#ffffff", // hero (adjust as needed)
    "#ffffff", // simple
    "#ffffff", // description
    "#ffffff", // chart
    "#ffffff", // exampleUses
    "#ffffff", // callToAction
    "#ffffff", // website
  ];
  
  const [randomFriendsCount, setRandomFriendsCount] = useState(0);
  useEffect(() => {
    setRandomFriendsCount(Math.floor(Math.random() * 4));
  }, []);

  const [showCursorTrail, setShowCursorTrail] = useState(false);
  useEffect(() => {
    setShowCursorTrail(sectionsWithCursorTrail.includes(sections[activeSection]));
  }, [activeSection]);

  const [transitioning, setTransitioning] = useState(false);
  // When activeSection changes, trigger the transition effect for dots
  useEffect(() => {
    setTransitioning(true);
    const timeout = setTimeout(() => {
      setTransitioning(false);
    }, 1000); // Duration should match your scroll-snap transition (1.5s)
    return () => clearTimeout(timeout);
  }, [activeSection]);

  // For navigation dots, we calculate a temporary vertical offset when transitioning.
  const getDotOffset = (index: number) => {
    if (!transitioning) return 0;
    if (index < activeSection) return -15; // move up by 10px
    if (index > activeSection) return 15;  // move down by 10px
    return 0; // active dot remains centered
  };

  return (
    <>
      {/* Scroll Snap Container */}
      <div className="relative w-screen h-screen overflow-hidden" ref={containerRef}>
        <motion.div
          className="absolute top-0 left-0 w-full"
          style={{ height: `${sections.length * 100}vh` }}
          animate={{ y: `-${activeSection * 100}vh` }}
          transition={{ type: "tween", duration: 1.5, ease: "easeInOut" }}
        >
          {sections.map((section, index) => (
            <section
              key={section}
              id={section}
              className="h-screen w-screen flex items-center justify-center"
              style={{ backgroundColor: backgroundColors[index] }}
            >
              <div className="text-center w-full">
                {sectionComponents[index]}
                {index === sections.length - 1 && (
                  <BottomBouncingDatadiniIcon friendsCount={randomFriendsCount} />
                )}
              </div>
            </section>
          ))}
        </motion.div>
      </div>

       {/* Navigation Indicators (dots) on the right */}
      <nav className="fixed right-8 top-1/2 transform -translate-y-1/2 z-10">
        <ul className="flex flex-col gap-4">
          {sections.map((section, index) => (
            <li key={section}>
              <motion.button
                onClick={() => setActiveSection(index)}
                initial={{ y: 0, scale: 1 }}
                animate={{ 
                  y: getDotOffset(index),
                  scale: activeSection === index ? 1.5 : 1
                }}
                transition={{ type: "spring", stiffness: 20, damping: 10 }}
                className="w-4 h-4 block rounded-full bg-gray-300 "
                aria-label={`Go to section ${section}`}
              />
            </li>
          ))}
        </ul>
      </nav>

      {/* Conditionally render the cursor trail effect on specified sections */}
      {sectionsWithCursorTrail.includes(sections[activeSection]) && (
        <DatadiniCursorTrail />
      )}

      {/* Fixed Footer that fades in on the last section */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: activeSection === sections.length - 1 ? 1 : 0 }}
        transition={{ duration: 0.5 }}
        className="fixed bottom-0 left-0 right-0"
      >
        <HomepageFooter />
      </motion.div>
    </>
  );
}
