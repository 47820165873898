// @ts-nocheck
'use client'

import React, { useEffect, useRef, useMemo } from "react";
import anime from "animejs";
import { DatadiniIcon } from "@/components/Icons";

interface BottomBouncingDatadiniIconProps {
  friendsCount?: number; // Number of friend icons to display (up to your max threshold)
}

export default function BottomBouncingDatadiniIcon({
  friendsCount = 0,
}: BottomBouncingDatadiniIconProps) {
  const mainIconRef = useRef<HTMLDivElement>(null);

  // Generate friend configurations: each with a random left position.
  // All friends use the same fixed size (96px).
  const friendConfigs = useMemo(() => {
    return Array.from({ length: friendsCount }).map(() => {
      const leftPercent = Math.random() * 80 + 10; // random between 10% and 90%
      return { left: leftPercent, size: 96 };
    });
  }, [friendsCount]);

  // Bounce animation function
  function bounceIcon(iconEl: HTMLElement) {
    // Remove any existing animation on this element to prevent stacking.
    anime.remove(iconEl);

    const minBounceHeight = 30;
    const maxBounceHeight = 50; // maximum jump height is now 50% of viewport height
    const minBounceDuration = 1000;
    const maxBounceDuration = 2500;
    const maxHorizontalDrift = 20;
    const maxSway = 15;

    const viewportHeight = window.innerHeight;
    const minPx = (minBounceHeight / 100) * viewportHeight;
    const maxPx = (maxBounceHeight / 100) * viewportHeight;
    const bounceHeight = Math.random() * (maxPx - minPx) + minPx;
    const totalDuration =
      Math.random() * (maxBounceDuration - minBounceDuration) +
      minBounceDuration;
    const upDuration = totalDuration * 0.4;
    const downDuration = totalDuration * 0.6;
    const drift =
      Math.random() * (maxHorizontalDrift * 2) - maxHorizontalDrift;
    const sway1 = Math.random() * (maxSway * 2) - maxSway;
    const sway2 = Math.random() * (maxSway * 2) - maxSway;

    anime
      .timeline({
        complete: () => bounceIcon(iconEl),
      })
      .add({
        targets: iconEl,
        scaleX: 1.2,
        scaleY: 0.8,
        duration: totalDuration * 0.1,
        easing: "easeInOutCubic",
      })
      .add({
        targets: iconEl,
        translateX: drift + sway1,
        translateY: -bounceHeight,
        scaleX: 1.0,
        scaleY: 1.0,
        duration: upDuration,
        easing: "easeOutCubic",
      })
      .add({
        targets: iconEl,
        translateX: drift + sway2,
        translateY: 0,
        duration: downDuration,
        easing: "easeInCubic",
      })
      .add({
        targets: iconEl,
        scaleX: 1.3,
        scaleY: 0.7,
        duration: totalDuration * 0.1,
        easing: "easeInOutCubic",
      })
      .add({
        targets: iconEl,
        scaleX: 1.0,
        scaleY: 1.0,
        duration: totalDuration * 0.1,
        easing: "easeInOutCubic",
      });
  }

  useEffect(() => {
    if (mainIconRef.current) {
      mainIconRef.current.style.color = "#000000"; // Main icon in black
      bounceIcon(mainIconRef.current);
    }
  }, []);

  return (
    <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
      {/* Main Icon: always centered */}
      <div
        ref={mainIconRef}
        style={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: "96px",
          height: "96px",
          zIndex: 20,
        }}
      >
        <DatadiniIcon />
      </div>
      {/* Friend Icons */}
      {friendConfigs.map((config, index) => (
        <FriendIcon key={index} left={config.left} size={config.size} bounceIcon={bounceIcon} />
      ))}
    </div>
  );
}

interface FriendIconProps {
  left: number;
  size: number;
  bounceIcon: (el: HTMLElement) => void;
}

function FriendIcon({ left, size, bounceIcon }: FriendIconProps) {
  const friendRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (friendRef.current) {
      friendRef.current.style.color = "#ff29e6"; // Friend icons in pink
      bounceIcon(friendRef.current);
    }
  }, [bounceIcon]);

  return (
    <div
      ref={friendRef}
      style={{
        position: "absolute",
        bottom: 0,
        left: `${left}%`,
        width: `${size}px`,
        height: `${size}px`,
        zIndex: 20,
      }}
    >
      <DatadiniIcon />
    </div>
  );
}
