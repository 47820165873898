// @ts-nocheck
'use client'

import { useEffect, useState } from "react";
import { useMotionValue, useSpring } from "framer-motion";

interface AnimatedNumberProps {
  value: number;
  duration?: number;    // Animation duration in seconds
  decimals?: number;    // Number of decimal places to show
  isActive?: boolean;   // Whether the animation should run
  delay?: number;       // Delay in milliseconds before starting the animation
}

export default function AnimatedNumber({
  value,
  duration = 1.5,
  decimals = 0,
  isActive = false,
  delay = 1500,
}: AnimatedNumberProps) {
  const motionValue = useMotionValue(0);
  const springValue = useSpring(motionValue, {
    damping: 20,
    stiffness: 100,
    duration: duration,
  });
  const [displayValue, setDisplayValue] = useState(0);

  // Effect that runs whenever isActive changes.
  useEffect(() => {
    if (isActive) {
      // Reset to 0 and then trigger the animation after the specified delay.
      motionValue.set(0);
      const timer = setTimeout(() => {
        motionValue.set(value);
      }, delay);
      return () => clearTimeout(timer);
    } else {
      // Reset the value so that the animation can repeat next time the section becomes active.
      motionValue.set(0);
    }
  }, [isActive, value, delay, motionValue]);

  // Subscribe to spring updates to update displayValue.
  useEffect(() => {
    const unsubscribe = springValue.onChange((latest) => {
      setDisplayValue(latest);
    });
    return () => unsubscribe();
  }, [springValue]);

  return <span>{displayValue.toFixed(decimals)}</span>;
}
